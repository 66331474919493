import Vue from "vue";
import Router from "vue-router";
import FutureHistory from "./components/FutureHistory";
import FutureRealtime from "./components/FutureRealtime";
import SpotHistory from "./components/SpotHistory";
import SpotRealtime from "./components/SpotRealtime";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/future",
    },
    {
      path: "/future",
      name: "future-history",
      component: FutureHistory,
    },
    {
      path: "/future/realtime",
      name: "future-realtime",
      component: FutureRealtime,
    },
    {
      path: "/future/volume",
      name: "future-volume",
      component: () => import("@/components/FutureVolume.vue"),
    },
    {
      path: "/spot",
      name: "spot-history",
      component: SpotHistory,
    },
    {
      path: "/spot/realtime",
      name: "spot-realtime",
      component: SpotRealtime,
    },
    {
      path: "/okx/future",
      name: "okx-future-history",
      component: () => import("@/components/OkxFutureHistory.vue"),
    },
    {
      path: "/okx/future/volume",
      name: "okx-future-volume",
      component: () => import("@/components/OkxFutureVolume.vue"),
    },
  ],
});
