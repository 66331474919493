<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-10">
                <h3 style="text-align: center">CryptoBreadth BN Perpetual</h3>
            </div>
            <div class="col-sm-1" style="padding-top: 20px;">
                <a href="/#/spot" class="btn btn-primary">BN现货情绪值</a>
            </div>
            <div class="col-sm-1" style="padding-top: 20px;">
                <a href="/#/future/volume" class="btn btn-primary">BN期货交易量</a>
            </div>
        </div>

        <div class="row>" style="height: 10px"></div>

        <History type='future'></History>
    </div>
</template>

<script>
import moment from 'moment'
import History from './History.vue'

export default {
  components: {
    History
  },
  methods: {
    exportExcel() {
        this.$http.get(
            'future/histories/export', {
                responseType: 'blob' // important
            }
        ).then((res) => {
            const filename = `future-${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}.xlsx`
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            console.error(err)
        })
    }
  }
}
</script>