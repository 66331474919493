import moment from "moment";

function formatAmount(amount) {
  return amount.replace(/0+$/, "").replace(/\.+$/, "");
}

function formatVolume(volume) {
  return (volume / 1e6).toFixed(2) + "M";
}

function volumeToM(volume) {
  return (volume / 1e6).toFixed(0);
}

function formatTime(t) {
  return moment(new Date(t)).format("YYYY-MM-DD HH:mm:ss");
}

function formatTicker(ticker) {
  return moment(new Date(ticker * 1000)).format("YYYY-MM-DD HH:mm");
}

function moodToBgcolor(mood) {
  switch (mood) {
    case -10:
      return "#f75e60";
    case -9:
      return "#f76c6e";
    case -8:
      return "#f77b7d";
    case -7:
      return "#f88a8c";
    case -6:
      return "#f8999c";
    case -5:
      return "#f9a9ac";
    case -4:
      return "#f9b9bb";
    case -3:
      return "#f9c8cc";
    case -2:
      return "#fad9dd";
    case -1:
      return "#faeaee";
    case 0:
      return "#fcfcff";
    case 1:
      return "#eaf5f0";
    case 2:
      return "#d9eee1";
    case 3:
      return "#c9e7d2";
    case 4:
      return "#b7e0c4";
    case 5:
      return "#a7d8b5";
    case 6:
      return "#97d1a7";
    case 7:
      return "#86cb99";
    case 8:
      return "#77c48b";
    case 9:
      return "#68bd7e";
    case 10:
      return "#59b670";
    default:
      return "";
  }
}

export default {
  formatAmount,
  formatVolume,
  volumeToM,
  formatTime,
  formatTicker,
  moodToBgcolor,
};
