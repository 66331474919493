<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-10">
                <h3 style="text-align: center">CryptoBreadth BN Spot</h3>
            </div>
            <div class="col-sm-1" style="padding-top: 20px;">
                <a href="/#/future" class="btn btn-primary">BN期货情绪值</a>
            </div>
            <div class="col-sm-1" style="padding-top: 20px;">
                <a href="/#/future/volume" class="btn btn-primary">BN期货交易量</a>
            </div>
        </div>

        <div class="row>" style="height: 10px"></div>

        <History type='spot'></History>
    </div>
</template>

<script>
import moment from 'moment'
import History from './History.vue'

export default {
  components: {
    History
  },
  methods: {
  }
}
</script>