<template>
    <div class="row">
        <h4 id='loading' align="center">加载中...</h4>
        <table id="table" class="table table-striped table-bordered">
            <thead>
                <tr id='thRealtimeRanks'>
                </tr>
                <tr id='thRanks'>
                </tr>
                <tr id='thRankChangeds'>
                </tr>
                <tr id='thSymbols'>
                </tr>
            </thead>
            <tbody id='tbody'>
                
            </tbody>
        </table>
    </div>
</template>

<script>
import utils from '@/utils';

export default {
    props: ['type'],
    data() {
        return {
            rankList: null,
            realtimeRankList: null,
            rankChangedList: null,
            realtimeMoodList: null,
            tickerList: null,
            groupList: null,
        }
    },
    created() {
        const type = this.type.replace('-', '/')
        console.log(`type: ${type}`)
        const url = `${type}/histories`
        this.$http.get(url).then(res => {
            if (res.data.status != 'success') {
                console.error(res.data.message)
                return
            }

            document.getElementById('loading').style.display = 'none'

            const data = res.data.data;
            this.rankList = data.rankList
            this.realtimeRankList = data.realtimeRankList
            this.rankChangedList = data.rankChangedList
            this.realtimeMoodList = data.realtimeMoodList
            this.tickerList = data.tickerList
            this.groupList = data.groupList

            this.update()
        })
    },
    methods: {
        update() {
            //
            // thead
            //

            // 实时排名
            let html = `<th class="th-datetime fixed-column">实时排名</th>\n`
            for (const rank of this.realtimeRankList) {
                const className = rank ? '' : 'fixed-column2'
                html += `<th class="${className}">${rank ? rank : ''}</th>\n`
            }
            const thRealtimeRanks = document.getElementById('thRealtimeRanks')
            thRealtimeRanks.innerHTML = html

            // 4h排名
            html = `<th class="fixed-column">4H前排名</th>\n`
            for (const rank of this.rankList) {
                const className = rank.rank ? '' : 'fixed-column2'
                html += `<th class="${className}">${rank.rank ? rank.rank : ''}</th>\n`
            }
            const thRanks = document.getElementById('thRanks')
            thRanks.innerHTML = html

            // 排名变化
            html = `<th class="fixed-column">排名变化</th>\n`
            for (const [i, rankChanged] of this.rankChangedList.entries()) {
                if (!i) {
                    html += `<th class="fixed-column2"></th>`
                } else if (rankChanged > 0) {
                    html += `<th><font color="#40bf20">+${rankChanged}</font></th>`
                } else if (rankChanged < 0) {
                    html += `<th><font color="red">${rankChanged}</font></th>`
                } else {
                    html += `<th>0</th>`
                }
            }
            const thRankChangeds = document.getElementById('thRankChangeds')
            thRankChangeds.innerHTML = html

            // symbols
            html = `<th class="fixed-column">时间</th>\n`
            for (const [i, rank] of this.rankList.entries()) {
                const className = !i ? 'fixed-column2' : ''
                const symbol = rank.symbol.endsWith('USDT') ? rank.symbol.slice(0, -4) : rank.symbol
                html += `<th class="${className}">\n<font color="blue">${symbol}</font></th>\n`
            }
            const thSymbols = document.getElementById('thSymbols')
            thSymbols.innerHTML = html

            //
            // tbody
            //

            // 当前情绪值
            html = `<tr>\n`
            html += `<td class="fixed-column">当前</td>\n`
            html += this.moodListToHtml(this.realtimeMoodList)
            html += `</tr>\n`

            // 历史情绪值
            for (const [i, list] of this.groupList.entries()) {
                html += `<tr>\n`

                // 时间
                html += `<td class="fixed-column"> ${utils.formatTicker(this.tickerList[i])}</td>\n`

                // 情绪值
                html += this.moodListToHtml(list)

                html += `</tr>\n`
            }

            const tbody = document.getElementById('tbody')
            tbody.innerHTML = html
        },

        moodListToHtml(list) {
            let html = ''
            for (let [i, mood] of list.entries()) {
                if (!i) {
                    if (mood) {
                        const color = mood > 0 ? '#40bf20' : 'red'
                        html += `<td class="fixed-column2"><b><font color="${color}">${mood}</font></b></td>\n`
                    } else {
                        html += `<td class="fixed-column2"><b>${mood}</b></td>\n`
                    }
                    continue
                }

                mood |= 0
                html += `<td bgcolor="${utils.moodToBgcolor(mood)}">${mood} </td>\n`
            }
            return html
        },
    },
}
</script>

<style>
/* scoped后不生效 */
  td,  th {
    min-width: 60px;
  }
  .th-datetime {
    min-width: 80px;
  }
  .fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
.fixed-column2 {
  position: sticky;
  left: 80px;
  z-index: 1;
  background-color: #fff;
}
</style>

