<template>
    <div class="row">
        <h4 id='loading' align="center">加载中...</h4>
        <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th scope="col">更新时间</th>
          <th scope="col">币种</th>
          <th scope="col">价格</th>
          <th scope="col">Ma20</th>
          <th scope="col">情绪值</th>
          <th scope="col">目标振幅</th>
          <th scope="col">24小时交易量</th>
          <th scope="col">交易量排名(NOW)</th>
          <th scope="col">交易量排名(4H之前)</th>
          <th scope="col">排名变化</th>
        </tr>
        </thead>
        <tbody id='tbody'>
        </tbody>
      </table>     
    </div>
</template>


<script>
import utils from "@/utils";

export default {
    props: ['type'],
    data() {
        return {
            list: null,
        }
    },

    created() {
        const url = `${this.type}/realtimes`
        this.$http.get(url).then(res => {
            if (res.data.status != 'success') {
                console.error(res.data.message)
                return
            }

            document.getElementById('loading').style.display = 'none'

            this.list = res.data.data

            this.update()
        })
    },

    methods: {
        update() {
            let html = ''
            for (const data of this.list) {
                html += '<tr>\n'

                html += `<td>${utils.formatTime(data.updatedAt)}</td>\n`
                const symbol = data.symbol.endsWith('USDT') ? data.symbol.slice(0, -4) : data.symbol
                html += `<td>${symbol}</td>\n`
                html += `<td>${utils.formatAmount(data.price)}</td>\n`
                html += `<td>${utils.formatAmount(data.ma20)}</td>\n`
                html += `<td bgcolor="${utils.moodToBgcolor(data.mood)}">${data.mood} </td>`
                html += `<td>${data.changePercent}%</td>`
                html += `<td>${utils.formatVolume(data.volume24h)}</td>`
                html += `<td>${data.rank}</td>`
                html += `<td>${data.rank+data.rankChanged}</td>`

                if (data.rankChanged) {
                    const color = data.rankChanged > 0 ? '#40bf20' : 'red'
                    html += `<td><font color="${color}">${ data.rankChanged}</font></td>`
                } else {
                    html += `<td>0</td>`
                }

                html += '</tr>\n'
            }

            const tbody = document.getElementById('tbody')
            tbody.innerHTML = html
        }
    }
}
</script>