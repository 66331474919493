import Vue from "vue";
import axios from "axios";
import VueMoment from "vue-moment";
import moment from "moment";
import App from "./App.vue";
import router from "./router";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

Vue.prototype.$http = axios.create({
  baseURL: "https://cryptobreadth.com/api/v1",
});

Vue.use(VueMoment, { moment });

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
