<template>
    <div class="container-fluid">
        <div class="row>" style="height: 10px"></div>

        <div class="row">
            <ul class="nav nav-pills" style="padding-left: 20px;">
            <li class="active">
                <a>BN实时数据</a>
            </li>
            <li>
                <a href="/#/spot">BN历史数据</a>
            </li>
            </ul>
        </div>

        <div class="row>" style="height: 10px"></div>

        <Realtime type='spot'></Realtime>
    </div>
</template>

<script>
import Realtime from './Realtime.vue'

export default {
  components: {
    Realtime
  }
}
</script>